@import './../../../assets/colors';
@import './../../../assets/breakpoints';
@import './../../../assets/typography';

.dashboard {
  flex: 1;
  display: flex;

  &__blank {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__frame {
    flex: 1;
    border: 0;
  }
}
